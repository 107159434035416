/* global twq */
import React, { Component, Fragment, Suspense, lazy, useEffect, useState } from "react";
import Intercom from '@intercom/messenger-js-sdk';
import Header from "./components/Header";
import "./App.scss";
// import './App.css'
import { Route, Navigate, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "./context/AuthContext";
import ThemeProvider from "./context/ThemeContext";
import SocketProvider from "./context/SocketioContext";
import FilterProvider from "./context/FilterContext";
import { Layout } from "./Layout";
import Loader from "./components/Loader";
import { localStorageKey } from "./utils/config";
import SubscriptionGuard from "./components/SubscriptionGuard";
import { SUBSCRIPTION_PLANS } from "./constant";
import TreemapComponent from './Treemap'; // Correctly import your Treemap component
import { facebookConversionAPI } from '@cataline/facebook-conversion-api'
import { getFacebookCookies } from 'src/utils/facebookConversionApi'
import { initTwitter, sendTwitterConversion } from './utils/twttierConversionApi';

const Loadable = (Component) => (props) => {
  const LazyComponent = lazy(Component);
  return (
    <Suspense
      fallback={
        <div className="page-loader">
          <img src={Loader} />
        </div>
      }
    >
      <LazyComponent {...props} />
    </Suspense>
  );
};

const RenderPrivateComponents = (Component, showNav = true) => {
  const token = localStorage.getItem(localStorageKey.token);
  const { user } = useAuth();

  if (user === null && token !== null) {
    return (
      <div className="page-loader">
        <img src={Loader} />
      </div>
    );
  }


  Intercom({
    app_id: 'jrgw0lsx',
    ...(user ? {
      user_id: user.userId,
      name: user.name,
      email: user.email,
      created_at: Math.floor(new Date(user.createdAt).getTime() / 1000)
    } : {
    })
  });
  return token ? (
    <SocketProvider user={user}>
      <FilterProvider>
        {/* {showNav && <Header.Private />} */}
        {Component}
      </FilterProvider>
    </SocketProvider>
  ) : (
    <Navigate to="/login" />
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});


const App = () => {
  const { user } = useAuth();
  facebookConversionAPI.init({
    accessToken: 'EAAH4gNnZCjbYBO6ryEVt9fpVLV99q5SMY5EnWan0pC0GUy3EZBLNwK9G5ZCIcjlAA7T7ZBPX7zzRYf2lRJ9QHUcFWMv1P2pZCzdehIRVyxPBF9PiyS7cbsMOYcBhF2o63j5nUw0OoowqUj7ZBqKLriCkjFZBIGhnKzXMUV0lT2qLDZBdcZCpP4TEcIwh4Df434kR9FgZDZD',
    pixelId: 1095213492026726,
    actionSource: 'website',
  })
  initTwitter();
  if (user?.email){
    sendTwitterConversion(user?.email,'tw-oo5yx-oo5zd');
  }
    

  const { fbc, fbp } = getFacebookCookies();

  facebookConversionAPI.setUserData({
    emails: [user?.email || ''],              // Hashed using SHA-256
    phones: [user?.phone || ''],              // Hashed using SHA-256 (provide phone if available)
    firstName: user?.firstName || '',  // From user object
    lastName: user?.lastName || '',       // From user object
    // dateOfBirth: '19970216',                 // Replace with actual DOB if available (YYYYMMDD format)
    // gender: 'm',                             // 'm' for male, 'f' for female (replace with actual gender if available)
    // city: 'São Paulo',                       // Replace with actual city if available
    // state: 'SP',                             // Replace with actual state if available
    // zip: '01100000',                         // Replace with actual zip code if available
    // country: 'br',                           // Replace with actual country code if available
    // clientIpAddress: '192.168.1.1',          // Use actual client IP address
    clientUserAgent: navigator.userAgent,    // User agent from the browser
    fbc: fbc || '',                          // Retrieved from cookies
    fbp: fbp || '',                                                  // Optional Facebook browser ID (replace if available)
    facebookLoginId: user?.username || '',          // Optional Facebook login ID (replace with actual ID)
    externalId: user?.userId || ''  // Optional external user ID
  });



  return (
    <>

      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ToastContainer />
          <SocketProvider>
            <Routes>
              {/* Public Routers */}
              <Route exact path="/" element={<LandingPages user={user} />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<SignUp />} />
              {/* <Route exact path="/b1-opra" element={<OpraB1 />} /> */}
              <Route exact path="/reset" element={<UpdatePassword />} />

              <Route
                exact
                path="/account/verify-email"
                element={<AccountVerification />}
              />
              <Route
                exact
                path="/terms-condition"
                element={<TermsConditions user={user} />}
              />
              <Route
                exact
                path="/privacy-policy"
                element={<PrivacyPolicy user={user} />}
              />
              <Route exact path="/faq" element={<Faqs user={user} />} />
              <Route
                exact
                path="/contact-us"
                element={<Contactus user={user} />}
              />
              {/* Private Router */}
              <Route path="/" element={RenderPrivateComponents(<Layout user={user} />)}>
                <Route
                  index
                  exact
                  path="/dashboard"
                  element={RenderPrivateComponents(<Home user={user} />)}
                />
                <Route
                  exact
                  path="/dashboardV2"
                  element={RenderPrivateComponents(<Dashboard user={user} />)}
                />
                <Route
                  exact
                  path="/bookmark"
                  element={RenderPrivateComponents(<BookMark user={user} />)}
                />
                <Route
                  exact
                  path="/news"
                  element={RenderPrivateComponents(<News user={user} />)}
                />
                <Route
                  exact
                  path="/hottest-contracts"
                  element={RenderPrivateComponents(<HotChainTicker user={user} />)}
                />
                <Route
                  exact
                  path="/dark-pool"
                  element={RenderPrivateComponents(<DarkPool user={user} />)}
                />
                <Route
                  exact
                  path="/unusual-flow-picks"
                  element={RenderPrivateComponents(<Unusualflow user={user} />)}
                />
                <Route
                  exact
                  path="/tickers"
                  element={RenderPrivateComponents(<Ticker />)}
                />
                <Route
                  exact
                  path="/alerts"
                  element={RenderPrivateComponents(<Alerts />)}
                />
                <Route
                  exact
                  path="/top-gainer-loser"
                  element={RenderPrivateComponents(<TopGainerAndLoser />)}
                />
                <Route
                  exact
                  path="/heatmap"
                  element={RenderPrivateComponents(<Heatmap />)}
                />
                <Route
                  exact
                  path="/tickers/:ticker/details"
                  element={RenderPrivateComponents(<Ticker />)}
                />
                <Route
                  exact
                  path="/my-account"
                  element={RenderPrivateComponents(<MyAccount user={user} />)}
                >
                  <Route
                    path=""
                    element={RenderPrivateComponents(<ProfileForm />, false)}
                  />
                  <Route
                    path="password"
                    element={RenderPrivateComponents(<PasswordReset />, false)}
                  />
                  <Route
                    path="plan"
                    element={RenderPrivateComponents(<Plan />, false)}
                  />
                </Route>
              </Route>

              <Route
                exact
                path="/b1form"
                element={RenderPrivateComponents(<OpraB1 />, false)}
              />


              <Route
                exact
                path="/pricing"
                element={RenderPrivateComponents(<Pricing user={user} />, false)}
              />
              {/* <Route
              exact
              path="/available-components"
              element={<AvailableComponents user={user} />}
            />
            <Route
              exact
              path="/quick-filters"
              element={<QuickFilters user={user} />}
            /> */}


              <Route
                exact
                path="/payment/payment-initiate/:planSlug"
                element={RenderPrivateComponents(<Payment user={user} />)}
              />



              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </SocketProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

const Dashboard = Loadable(() => import("./containers/Dashboard-old"));
const Payment = Loadable(() => import("./containers/Payment/Payment"));
const Login = Loadable(() => import("./containers/Login"));
const Home = Loadable(() => import("./containers/Dashboard"));
// const Home = Loadable(() => import("./containers/Home"));
const OpraB1 = Loadable(() => import("./components/Forms/OpraAgreement"));
const Features = Loadable(() => import("./containers/Features"));
const Pricing = Loadable(() => import("./containers/Pricing"));
const DiscoverBot = Loadable(() => import("./containers/DiscoverBot"));
const AffiliateProgram = Loadable(() =>
  import("./containers/AffiliateProgram")
);
const SignUp = Loadable(() => import("./containers/SignUp"));
const AvailableComponents = Loadable(() =>
  import("./containers/Home/AvailableComponents")
);
const QuickFilters = Loadable(() => import("./containers/Home/QuickFilters"));
const News = Loadable(() => import("./containers/News"));
const MyAccount = Loadable(() => import("./containers/MyAccount"));
const Notifications = Loadable(() =>
  import("./containers/MyAccount/Notifications")
);
const ProfileForm = Loadable(() => import("./components/Forms/ProfileForm"));
const PasswordReset = Loadable(() =>
  import("./components/Forms/PasswordReset")
);
const Plan = Loadable(() => import("./containers/MyAccount/Plan"));
const AccountVerification = Loadable(() =>
  import("./containers/MyAccount/AccountVerification")
);
const LandingPages = Loadable(() => import("./LandingPages"));
const BookMark = Loadable(() => import("./containers/BookMark"));
const UpdatePassword = Loadable(() =>
  import("./containers/Password/UpdatePassword"));
const PrivacyPolicy = Loadable(() => import("./containers/PrivacyPolicy"));
const TermsConditions = Loadable(() => import("./containers/TermsConditions"));
const Faqs = Loadable(() => import("./containers/Faqs"));
const Contactus = Loadable(() => import("./containers/Contactus"));
const TopGainerAndLoser = Loadable(() =>
  import("./containers/Home/TopGainerAndLoser")
);
const Heatmap = Loadable(() =>
  import("./containers/Home/Heatmap")
);
const HotChainTicker = Loadable(() =>
  import("./containers/LiveHotChain/HotChainTicker")
);
const DarkPool = Loadable(() => import("./containers/DarkPool"));
const Unusualflow = Loadable(() =>
  import("./containers/Unusualflow/Unusualflow"));
const Alerts = Loadable(() => import("./containers/Alerts/Alerts"));
const Ticker = Loadable(() => import("./containers/Tickers/TickerDetail"));

export default App;
