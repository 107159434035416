import { useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";
import { convertStringsToNumbers } from "../utils";
import { refetchInterval } from "../constant";
import { isMarketOpen } from "src/utils/time";
import { useContext } from "react";
import { SocketioContext } from "src/context/SocketioContext";
import { apiRefreshInterval } from "src/utils/user"
import { useIsPremiumUser } from "src/context/AuthContext";

export const queryKey = {
  getTrades: "getTrades",
};

export async function getTrades(body = { isAlerts: false, liveFeed: true }) {
  const url = 'options-data';
  delete body.isAlerts;

  if (!body.liveFeed) {
    return Promise.resolve([]);
  }
  // if (!body.initial && !body.lastValueTime) {
  //   return Promise.resolve([]);
  // }
  body.count = 50;
  if (body.initial || !body.lastValueTime) {
    body.count = 200;
  }
  if (body.initial === false) {
    body.count = 7;
  }
  //   body.count = 50;
  // } else {
  //   body.count = 5;
  //   body.isLive = true
  // }

  delete body.initial
  let res = await axios.post(url, convertStringsToNumbers(body));
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}
const getEmpty = Promise.resolve([])

export const useGetTrades = (params) => {
  const { liveFeed } = useContext(SocketioContext)
  const isPremium = useIsPremiumUser();


  return useQuery({
    queryKey: [queryKey.getTrades],
    queryFn: () => getTrades({ ...params, liveFeed}),
    refetchInterval: 5000,
    // refetchInterval: apiRefreshInterval({ isLiveFeed: liveFeed, isPremium }),
    refetchIntervalInBackground: true,
    enabled: true,
    refetchOnWindowFocus: true,
    staleTime: 0
  });
};
