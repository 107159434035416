export const BASE_URL = process.env.REACT_APP_AWS_URL;


export const localStorageKey = {
    token: 'token',
    userInfo: 'userInfo',
}

export const apiURL = {
    news: 'news'
}
