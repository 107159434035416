import React from "react";

function CopyRights() {
  return (
    <span className="text-gray-600 fs-11 me-2">
        @ {new Date().getFullYear()} Unusual Flow
      </span>
  );
}

export default CopyRights;
