import { facebookConversionAPI } from '@cataline/facebook-conversion-api';

// Track a specific event, such as button clicks
export const trackEvent = (eventName: string, params: any) => {
  facebookConversionAPI.trackEvent({
    name: eventName,
    params: params,        // Parameters specific to the event, e.g., value, currency, etc.
    sourceUrl: window.location.href,  // URL where the event occurred
  });
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
};

// Get Facebook click ID (fbc) and browser ID (fbp) from cookies
export const getFacebookCookies = () => {
  const fbc = getCookie('_fbc') || '';  // '_fbc' is the default name for the Facebook click ID cookie
  const fbp = getCookie('_fbp') || '';  // '_fbp' is the default name for the Facebook browser ID cookie

  return { fbc, fbp };
};
