
import SideBar from 'src/components/Header/SideBar';
import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ThemeDetailsContext } from './context/ThemeContext';
import FooterDashboard from 'src/components/Header/Footer/FooterDashboard';
import { useAuth } from "src/context/AuthContext";
import Header from './components/Header';
import hasAccess from "src/utils/guard";
import { SUBSCRIPTION_PLANS } from './constant';
import { trackEvent } from './utils/facebookConversionApi';
import { sendTwitterConversion } from './utils/twttierConversionApi';
declare const twq: (eventType: string, eventName: string, data?: Record<string, any>) => void;

export const Layout = () => {
    const { theme, setTheme } = useContext(ThemeDetailsContext)
    const navigate = useNavigate();

    const { user } = useAuth();

    const hasAcess = hasAccess([SUBSCRIPTION_PLANS.PREMIUM_PLUS, SUBSCRIPTION_PLANS.PROFESSIONAL], user)
    if (!hasAcess) {
        navigate('/pricing')
    }else {
        const currentTimestamp = Date.now();
        const timeDifference = currentTimestamp - user.current_period_start;
        if (timeDifference < 10 * 60 * 1000) {
            trackEvent('SubscribeEvent', {
                emails: [user.email || ''],
                firstName: user.firstName || '',
                lastName: user?.lastName || '',
                name: `${user.firstName} ${user.lastName}`,
            });
            sendTwitterConversion(user?.email, 'tw-oo5yx-oo5zi');
            // window.twq('event', ' tw-oo5yx-oo5zi', {
            //     email_address: user.email
            // });
        } 
    }

   
    return (
        <>
            {
                hasAcess && <Header.Private />
            }

            <main className='app-main d-flex'>
                <SideBar theme={theme} setTheme={setTheme} />
                <div className='app-content d-flex flex-grow-1 flex-column position-relative'>
                    <div id='container-fluid' className='container-fluid p-0 p-lg-3'>

                        <div className='app-card'>
                            {
                                hasAcess && <Outlet />
                            }

                        </div>
                    </div>
                    <FooterDashboard />
                </div>
            </main>
        </>
    );
}
