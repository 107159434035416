import axios from '../utils/axios';

export const queryKey = {
  login: 'login',
  signup:'signup'
};

export async function loginApi(params) {
  let res = await axios.post('/login', params);
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 

export async function signUpApi(params) {
  let res = await axios.post('/signup', params);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 
export async function changePasswordApi(params) {
  let res = await axios.post('/change-user-password', params);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 

// api to update password using token
export async function resetPassword(params) {
  let res = await axios.post('/update-password-reset', params);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 
export async function userDetailsApi(params) {
  let res = await axios.post('/user-details', params);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data?.data?.user);
  } else {
    return Promise.reject(data);
  }
}; 
export async function updateUserDetailsApi(params) {
  let res = await axios.post('/update-user-details', params);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data?.data?.user);
  } else {
    return Promise.reject(data);
  }
}; 
export async function b1formApi(params) {
  let res = await axios.post('/user-b1-form', params);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 
export async function verifyEmailApi({token}) {
  const url = `/verify-email?verificationToken=${token}`
  let res = await axios.get(url);
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 
export async function resetPasswordLink({email}) {
  const url = `/password-reset-link`
  let res = await axios.post(url, {email});
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}; 
export async function resendVerificationLink({email}) {
  const url = `/resend-verification`
  let res = await axios.post(url, {email});
  const data = res.data;
  if (data.success) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data.message);
  }
}; 
