import React from "react";
import CopyRights from "./CopyRights";
// import TechnologyPartner from "./TechnologyPartner";

function FooterLegal() {
  return (
    <div className="mt-md-2 mt-3 d-flex flex-column flex-sm-row align-items-center justify-content-center text-gray-600  text-center fs-11 mb-0">
      <CopyRights />
      <div className="d-flex flex-row align-items-center justify-content-center text-gray-600  text-center fs-11 mb-0">
        <span className="d-none d-sm-inline-block">|</span>
        <a href="/faq" className="text-gray-600 px-3  text-hover-dark">
          FAQ
        </a>
        <span className="">|</span>
        <a href="/contact-us" className="text-gray-600 px-3  text-hover-dark">
          Contact Us
        </a>
        <span>|</span>
        <a href="/terms-condition" className="text-gray-600 px-3  text-hover-dark">
          Terms & Conditions
        </a>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center text-gray-600  text-center fs-11 mb-0">
        <span className="d-none d-sm-inline-block">|</span>
        <a href="/privacy-policy" className="text-gray-600 px-3  text-hover-dark">
          Privacy Policy
        </a>
        <span>|</span>
        <span className="px-3">Not Financial Advice</span>
      </div>
    </div>
  );
}

export default FooterLegal;
