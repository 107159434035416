import { createContext, useState, useEffect, useCallback } from "react";
import io from "socket.io-client";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { filterEmptyObjects, jsonToQueryParms } from "../utils";
import http from "../utils/http";
import { setUserSession, getUserSession } from "../utils";
import { useGetPlansQuery } from "src/services/planService";
import { queryKey as tradeKey } from "src/services/tradeService";
import { useQueryClient } from "@tanstack/react-query";

export const SocketioContext = createContext();
let socket;
let filterOptions = {
  tickers: [],
  side: "",
  strikePrice: {
    from: "",
    to: "",
  },
  contractType: "",
  contractExpiration: {
    from: "",
    to: "",
  },
  bid: {
    from: "",
    to: "",
  },
  ask: {
    from: "",
    to: "",
  },
  price: {
    from: "",
    to: "",
  },
  size: {
    from: "",
    to: "",
  },
  premium: {
    from: "",
    to: "",
  },
  openInterest: {
    from: "",
    to: "",
  },
  volume: {
    from: "",
    to: "",
  },
  contractSize: {
    from: "",
    to: "",
  },
  type: "",
  sentiment: "",
};
const feedKeyMapping = {
  tickers: "tickers",
  sentiment: "sentiment",
  side: "side",
  strikePrice: "strikePrice",
  contractType: "contractType",
  contractExpiration: "contractExpiration",
  bid: "bid",
  ask: "ask",
  price: "price",
  size: "size",
  premium: "premium",
  openInterest: "openInterest",
  volume: "volume",
  contractSize: "contractSize",
  type: "type",
  exchange: "exchange",
  flag: "flag",
  // switch filters
  volumnGreaterOI: "volumnGreaterOI",
  sizeGreaterOI: "sizeGreaterOI",
  intradayOnly: "intradayOnly",
  excludeDeepItm: "excludeDeepItm",
  askSide: "askSide",
  otmOnly: "otmOnly",
  bidSide: "bidSide",
  multilegOnly: "multilegOnly",
  bearish: "bearish",
  bullish: "bullish",

  index: "index",
  etf: "etf",
  expringToday: "expringToday",
  expiringThisWeek: "expiringThisWeek",



  theta: "theta",
  iv: "iv",
  ro: "ro",
  gamma: "gamma",
  vega: "vega",
  delta: "delta",


  // flow indicators filters
  // hlvb: "hlvb",
  // arh: "arh",
  // ffs: "ffs",
  // otm: "otm",
  // smallCapFt: "smallCapFt",
  // voi: "voi",
  // largeCatFt: "largeCatFt",
  // rt: "rt",
  // drh: "drh",
  // midCapFt: "midCapFt",

  multileg: "multileg",
  cross: "cross",
  floor: "floor",
  sweep: "sweep",
  ipm: "ipm",
  otm: "otm",
};
let pageVal = 250;
const SocketProvider = ({ children, user }) => {
  const params = new URLSearchParams(window.location.search);
  const paramsObj = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
  filterOptions = {
    tickers: paramsObj?.tickers?.split(","),
    side: paramsObj?.side,
    sentiment: paramsObj?.sentiment,
    strikePrice: {
      from: paramsObj?.strikePrice_from,
      to: paramsObj?.strikePrice_to,
    },
    contractType: paramsObj?.contractType,
    contractExpiration: {
      from: paramsObj?.contractExpiration_from,
      to: paramsObj?.contractExpiration_to,
    },
    bid: {
      from: paramsObj?.bid_from,
      to: paramsObj?.bid_to,
    },
    ask: {
      from: paramsObj?.ask_from,
      to: paramsObj?.ask_to,
    },
    price: {
      from: paramsObj?.price_from,
      to: paramsObj?.price_to,
    },
    size: {
      from: paramsObj?.size_from,
      to: paramsObj?.size_to,
    },
    premium: {
      from: paramsObj?.premium_from,
      to: paramsObj?.premium_to,
    },
    openInterest: {
      from: paramsObj?.openInterest_from,
      to: paramsObj?.openInterest_to,
    },
    volume: {
      from: paramsObj?.volume_from,
      to: paramsObj?.volume_to,
    },
    contractSize: {
      from: paramsObj?.contractSize_from,
      to: paramsObj?.contractSize_to,
    },
    type: paramsObj?.type,
    exchange: paramsObj?.exchange,
    flag: paramsObj?.flag,

    // switch
    volumnGreaterOI: paramsObj?.volumnGreaterOI ? true : "",
    sizeGreaterOI: paramsObj?.sizeGreaterOI ? true : "",
    intradayOnly: paramsObj?.intradayOnly ? true : "",
    excludeDeepItm: paramsObj?.excludeDeepItm ? true : "",
    askSide: paramsObj?.askSide ? true : "",
    otmOnly: paramsObj?.otmOnly ? true : "",
    bidSide: paramsObj?.bidSide ? true : "",
    multilegOnly: paramsObj?.multilegOnly ? true : "",
    bearish: paramsObj?.bearish ? true : "",
    bullish: paramsObj?.bullish ? true : "",

    // indicators
    hlvb: paramsObj?.hlvb ? true : "",
    arh: paramsObj?.arh ? true : "",
    ffs: paramsObj?.ffs ? true : "",
    otm: paramsObj?.otm ? true : "",
    smallCapFt: paramsObj?.smallCapFt ? true : "",
    voi: paramsObj?.voi ? true : "",
    largeCatFt: paramsObj?.largeCatFt ? true : "",
    rt: paramsObj?.rt ? true : "",
    drh: paramsObj?.drh ? true : "",
    midCapFt: paramsObj?.midCapFt ? true : "",
  };
  let gmt = moment().utc();
  let unix = gmt.unix();
  let cstTimeStamp = unix - 21600;
  const [tableFilters, setTableFilters] = useState();
  const [events, setEvents] = useState([]);
  const [marketSentiment, setMarketSentiment] = useState({
    put: 0,
    call: 0,
    putPremium: 0,
    callPremium: 0,
  });
  const [liveFeed, setLiveFeed] = useState(true);
  const [topGainerAndLoser, setTopGaainerAndLoser] = useState({
    topGainer: [],
    topLoser: [],
  });
  const [filters, setFilters] = useState(filterEmptyObjects(filterOptions));
  const [appliedFilters, setAppliedFilters] = useState(
    filterEmptyObjects(filterOptions)
  );
  const [filterString, setFilterString] = useState("1==1");
  const [userPlan, setUserPlan] = useState({});
  const [query, setQuery] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [isDataLoading, setDataLoading] = useState(false);
  const [selectedCustomFilter, setCustomFilter] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [paymentClientSecret, setPaymentClientSecret] = useState(null);

  const { data: planList } = useGetPlansQuery();
  // const { data: userPlanDetail } = useUserPlanQuery({ userId: user?.userId });
  useEffect(() => {
    // socket.emit("join_room", "market_status");
    // socket.emit("join_room", "gainerLooser");
    // return () => socket.disconnect();
  }, []);
  useEffect(() => {
    let string = filterString.replace("1==1 && #ticker.includes()", "");
    string = filterString.replace("1==1", "");
    if (string.length) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [filterString]);
  // useEffect(() => {
  //   if (userPlanDetail) {
  //     setUserSession("planDetail", userPlanDetail);
  //     setUserPlan(userPlanDetail.plan);
  //   }
  // }, [userPlanDetail]);
  // useEffect(() => {
  //   if (userPlan && userPlan.planId === process.env.REACT_APP_LIVE_FEED_PLAN_ID) {
  //     setLiveFeed(true)
  //     setDataLoading(true)
  //     fetchTradeFromDb(query).then(result => {

  //       sortData(result)
  //     }).catch(error => {
  //       console.log(error)
  //     })
  //     socket.emit("join_room", "preminium_plus_user");
  //   }
  //   if (userPlan && userPlan.planId === process.env.REACT_APP_15MINS_FEED_PLAN_ID) {
  //     setDataLoading(true)
  //     fetchTradeFromDb(jsonToQueryParms(query)).then(result => {
  //       sortData(result)
  //     }).catch(error => {
  //       console.log(error)
  //     })
  //   }
  //   if (userPlan && userPlan.planId === process.env.REACT_APP_FREE_PLAN_ID) {
  //     setDataLoading(true)
  //     fetchTradeFromDb(jsonToQueryParms(query)).then(result => {
  //       sortData(result)
  //     }).catch(error => {
  //       console.log(error)
  //     })
  //   }

  // }, [userPlan])
  // useEffect(() => {
  //   if (userPlan && userPlan.planId === process.env.REACT_APP_15MINS_FEED_PLAN_ID) {
  //     var tradeInterval = setInterval(() => {
  //       fetchTradeFromDb(jsonToQueryParms(query)).then(result => {
  //         sortData(result)
  //       }).catch(error => {
  //         console.log(error)
  //       })
  //     }, (15 * 60 * 1000));
  //     return () => clearInterval(tradeInterval)
  //   }
  // }, [userPlan, query])
  // socket.on("receive_feed", (data) => {
  //   console.log(data)
  //   if (data && Object.keys(data).length) {

  //     handleEvent(data)
  //   }
  // });
  // socket.on("receive_market_status", (data) => {
  //   console.log(data)
  //   setMarketSentiment(data)
  // });
  // socket.on("top_gainer_looser", (data) => {
  //   setTopGaainerAndLoser(data)
  // });
  const sortData = (result) => {
    let val = [...new Set(result)].slice(0, pageVal);
    let sortValue = val.sort(
      (a, b) => parseFloat(b.netTimestamp) - parseFloat(a.netTimestamp)
    );
    setEvents(sortValue);
  };
  // const handleEvent = useCallback((tradeEvent) => {

  //   let insertData = []
  //   let filter = filterString
  //   tradeEvent.forEach(event => {
  //     filter = filter.replace("#side", event.side)
  //     filter = filter.replaceAll("#strikePrice", parseInt(event.strikePrice))
  //     filter = filter.replace("#contractType", event.contractType)
  //     filter = filter.replaceAll("#bid", parseInt(event.bid))
  //     filter = filter.replaceAll("#ask", parseInt(event.ask))
  //     filter = filter.replaceAll("#price", parseInt(event.price))
  //     filter = filter.replaceAll("#size", parseInt(event.size))
  //     filter = filter.replaceAll("#premium", parseInt(event.premium))
  //     filter = filter.replaceAll("#openInterest", parseInt(event.openInterest))
  //     filter = filter.replaceAll("#volume", parseInt(event.volume))
  //     filter = filter.replaceAll("#contractSize", parseInt(event.contractSize))
  //     filter = filter.replace("1==1 && #ticker.includes()", '1==1')
  //     // if (appliedFilters.ticker.length && appliedFilters.ticker[0] !== "") {
  //     //   let ticketString = "'" + appliedFilters.ticker.join("','") + "'";
  //     //   filter = filter + ` && [${ticketString}].includes('${event.ticker}')`
  //     // }
  //     if (eval(filter)) {
  //       insertData.push(event)
  //     }
  //   });
  //   if (insertData.length) {

  //     setEvents((prevState) => {
  //       let val = [...insertData, ...prevState]
  //       val = [...new Set(val)].slice(0, pageVal)
  //       let sortValue = val.sort((a, b) => parseFloat(b.netTimestamp) - parseFloat(a.netTimestamp));
  //       return sortValue
  //     })
  //   }

  // }, [events])
  const handelFilters = (filter) => {
    const res = filterEmptyObjects(filter);
    setFilters(res);
  };
  const handelAppliedFilters = (filters) => {
    let queryParams = {};
    let filterString = "1==1";
    for (let filter in filters) {
      if (filters[filter]) {
        if (
          !Array.isArray(filters[filter]) &&
          typeof filters[filter] == "object"
        ) {
          let filterItem = filters[filter];
          for (let item in filterItem) {
            if (filters[filter][item] !== undefined) {
              queryParams[`${feedKeyMapping[filter]}_${item}`] =
                filters[filter][item];
              if (filters[filter][item] != "" && item == "from") {
                filterString += ` && #${feedKeyMapping[filter]} >=${filters[filter][item]}`;
              }
              if (filters[filter][item] != "" && item == "to") {
                filterString += ` && #${feedKeyMapping[filter]} <= ${filters[filter][item]}`;
              }
            }
          }
        } else if (typeof filters[filter] == "string") {
          queryParams[feedKeyMapping[filter]] = filters[filter];
          if (filters[filter] !== "") {
            filterString += ` && '#${feedKeyMapping[filter]}' == '${filters[filter]}'`;
          }
        } else if (Array.isArray(filters[filter])) {
          queryParams[feedKeyMapping[filter]] = filters[filter];
        } else {
          queryParams[feedKeyMapping[filter]] = filters[filter];
        }
      }
    }
    setSearchParams(jsonToQueryParms(queryParams));
    setFilterString(filterString);
    setAppliedFilters(filters);
    setQuery(queryParams);
  };

  useEffect(() => {
    // Extract parameters from the URL
    const paramsObj = {};

    for (const [key, value] of searchParams.entries()) {

      paramsObj[key] = {
        filterType: "text",
        label: key,
        type: "text",
        field: key,
        filter: key !== 'ticker' ? true : value
      };
    }
    setTableFilters(paramsObj);
  }, [searchParams]);

  // useEffect(() => {
  //   if (tableFilters) {
  //     const newSearchParams = new URLSearchParams(searchParams);

  //     // Remove query parameters based on the updated tableFilters
  //     for (const [key] of newSearchParams.entries()) {
  //       const filterFieldExists = Object.values(tableFilters).some(
  //         (filter) => filter.field === key
  //       );
  //       // if (!filterFieldExists) {
  //       //   newSearchParams.delete(key); // Remove the parameter from the URL if it does not exist in tableFilters
  //       // }
  //     }


  //     // Set the new search params to remove them from the URL
  //     setSearchParams(newSearchParams);
  //   }
  // }, [tableFilters, searchParams, setSearchParams]); 

  const resetFiletr = () => {
    setFilterString("1=1");
    setAppliedFilters(filterOptions);
    setFilters(filterOptions);
    setFilterString("1==1");
  };
  return (
    <SocketioContext.Provider
      value={{
        tableFilters,
        setTableFilters,
        events,
        marketSentiment,
        liveFeed,
        topGainerAndLoser,
        setLiveFeed,
        handelFilters,
        filters,
        appliedFilters,
        handelAppliedFilters,
        filterApplied,
        resetFiletr,
        selectedCustomFilter,
        setCustomFilter,
        isDataLoading,
        socket,
        paymentClientSecret,
        setPaymentClientSecret
      }}
    >
      {children}
    </SocketioContext.Provider>
  );
};

export default SocketProvider;
