import { useQuery } from "@tanstack/react-query";
import axios from "../utils/axios";
import { apiRefreshInterval } from "../utils/user";
import _ from "lodash";
import { useIsPremiumUser } from "src/context/AuthContext";
import { useContext } from "react";
import { SocketioContext } from "src/context/SocketioContext";

export const queryKey = {
  getTicker: "getTicker",
  getHeader: "getHeader",
};

export async function getTickerDetails(body: any) {
  // const { liveFeed } = useContext(SocketioContext)
  // if (!liveFeed) {
  //   return Promise.resolve([]);
  // }

  let res = await axios.post("/ticker-details", body);
  const data = res.data;
  if (data.success !== false) {
    return Promise.resolve(data);
  } else {
    return Promise.reject(data);
  }
}
function aggregateData(data: any) {
  const aggregatedData: any = {
    dayLow: 0,
    netPutPremium: 0,
    dayOpenPrice: 0,
    bullishCount: 0,
    netCall: 0,
    netPut: 0,
    currentStockPrice: 0,
    previousDayClosePrice: 0,
    bearishCount: 0,
    dayHigh: 0,
    neutralCount: 0,
    netCallPremium: 0,
  };

  if (_.isArray(data)) {
    data.forEach((entry) => {
      for (const key in aggregatedData) {
        if (entry.hasOwnProperty(key)) {
          aggregatedData[key] += entry[key];
        }
      }
    });
  }

  return aggregatedData;
}

export const useGetHeaderDetails = () => {
  const isPremium = useIsPremiumUser();
  const { liveFeed } = useContext(SocketioContext);
  return useQuery({
    queryKey: [queryKey.getHeader],
    queryFn: async () => {
      const res = await getTickerDetails({ ticker: "All" });
      return aggregateData(res);
    },
    refetchInterval: apiRefreshInterval({ isPremium, isLiveFeed: liveFeed }),
    staleTime: 0,
    initialData: { netPut: 0, netCall: 0, netPutPremium: 0, netCallPremium: 0 },
  });
};

export const useGetTickerDetails = (ticker: any) => {
  const isPremium = useIsPremiumUser();
  const { liveFeed } = useContext(SocketioContext);

  return useQuery({
    queryKey: [queryKey.getTicker],
    queryFn: async () => {
      const res = await getTickerDetails({ ticker });
      return res[0] || {};
    },
    // enabled: !!ticker,
    refetchInterval: apiRefreshInterval({ isPremium, isLiveFeed: liveFeed }),
    staleTime: 0,
    // placeholderData: {},
    // staleTime: 0
  });
};
