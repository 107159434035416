import { isStockFastRefreshAvailable } from "@components/Heatmap/shared/isStockFastRefreshAvailable";
import { isMarketOpen } from "./time";
import hasAccess from "./guard";
import { SUBSCRIPTION_PLANS } from "src/constant";
import { useAuth } from "src/context/AuthContext";
import { useContext } from "react";
import { SocketioContext } from "src/context/SocketioContext";

enum ApiPollingIntervalInMs {
    Premium = 9000000,
    PremiumPlus = 120000,
    Professional = 60000,
}

export function apiRefreshInterval({
    isPremium = false,
    isLiveFeed = false,
    isFastRefreshInterval = false,
    premium = ApiPollingIntervalInMs.Premium,
    premiumPlus = ApiPollingIntervalInMs.PremiumPlus,
    professional = ApiPollingIntervalInMs.Professional,
}: { isLiveFeed?: boolean; isFastRefreshInterval?:boolean;isPremium?: boolean; premium?: number; premiumPlus?: number; professional?: number } = {}) {
    if (!isMarketOpen()) {
        return 0
    }

    if (isFastRefreshInterval){
        return 10000
    }
    let customRefreshInterval = premium
    if (!isPremium) {
        customRefreshInterval = premiumPlus
    }
    //  else if (!isStockFastRefreshAvailable()) {
    //     customRefreshInterval = professional
    // }

    return customRefreshInterval
}