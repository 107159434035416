import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import logom from '../../assets/images/m-logo.png'
import logowhite from '../../assets/images/dark_theme/logo-white.png'
import { Logo } from 'src/components/Logo'
import { trackEvent } from 'src/utils/facebookConversionApi'
import { ThemeDetailsContext } from '../../context/ThemeContext'
import bannerBackground from '../../../src/assets/images/banners/bg-laborday-american-flag.svg'
import bannerForeground from '../../../src/assets/images/banners/fg-laborday.svg'
import { debounce } from 'lodash'

const PublichHeader = () => {
  const { theme, setTheme } = useContext(ThemeDetailsContext)
  const navigate = useNavigate()
  const location = useLocation()
  const isHome = useMemo(() => {
    return location.pathname === '/'
  }, [location.pathname])
  const [sticky, setSticky] = useState('')

  const [activeMenu, setActiveMenu] = useState('home')

  // on render, set listener
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [])

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY
    const stickyClass = scrollTop >= 100 ? 'is-sticky' : ''
    setSticky(stickyClass)
    onBodyScroll()
  }
  const onBodyScroll = useCallback(
    debounce(() => {
      trackEvent('PageScroll', {
      });
    }, 1500),
    []
  );

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleNavItem = useCallback(
    async (to) => {
      if (!isHome) {
        // If not on the home page, navigate to the home page first
        await navigate('/home') // Assuming your home page route is '/home'
        setActiveMenu('home')
      }
      handleButtonClickSendEvent(to)

      // Scroll to the section
      setActiveMenu(to)
      scrollToSection(to)
    },
    [isHome, navigate]
  )
  const handleButtonClickSendEvent = (eventName) => {
    // Track a button click event
    trackEvent('ButtonClick', {
      button: eventName
    });
  };


  return (
    <div expand='lg' className={`navbar-landing fixed-top ${sticky}`} navbarScroll>
      <div className="d-none d-lg-block">
        <Navbar>
          <div className='container-xxl'>
            <Navbar.Brand onClick={() => { handleButtonClickSendEvent('logo'); navigate('/') }}>
              <Logo />
            </Navbar.Brand>
            <Navbar.Toggle className='ms-3' aria-controls={`offcanvasNavbar-expand-lg`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-md`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement='start'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                  <div className='d-flex align-items-center'>
                    <Logo icononly />
                    <span className='ms-4'>Unusual Flow</span>
                  </div>
                </Offcanvas.Title>
                {/* <div className="theme-toggle">
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="themeToggle"
                      role="switch"
                      checked={theme == "dark" ? true : false}
                      onClick={() => {
                        setTheme(theme == "dark" ? "light" : "dark");
                      }}
                    />
                </div>
              </div> */}
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className='justify-content-start justify-content-md-center flex-grow-1' navbarScroll>
                  <Nav.Link
                    onClick={() => handleNavItem('home')}
                    active={activeMenu === 'home'}
                    to='/'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleNavItem('features')}
                    active={activeMenu === 'features'}
                    to='/features'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Features
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleNavItem('heatmap')}
                    active={activeMenu === 'heatmap'}
                    to='/heatmap'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Heatmap
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleNavItem('pricing')}
                    active={activeMenu === 'pricing'}
                    to='/pricing'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Pricing
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => { handleButtonClickSendEvent("contact-us"); navigate('/contact-us') }}
                    active={activeMenu === '/contact-us'}
                    to='/contact-us'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
                <div className='mt-10 mt-lg-auto'>
                  <button
                    className='btn btn-light-primary btn-lg-sm me-3 px-lg-4 px-xl-10'
                    type='button'
                    onClick={() => { handleButtonClickSendEvent("login"); navigate('/login') }}
                    aria-label='Sign In'
                  >
                    Sign In
                  </button>
                  <button
                    className='btn btn-primary btn-lg-sm px-lg-4 px-xl-10'
                    type='button'
                    onClick={() => { handleButtonClickSendEvent("Free_Trial"); navigate('/signup') }}
                    aria-label='Sign Up'
                  >
                    Free Trial
                  </button>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </Navbar>
      </div>

      <span className="mob d-lg-none d-block">
        <Navbar expand={false}>
          <Container>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
            <Navbar.Brand href="/">
              <img src={logom} alt=""></img>
            </Navbar.Brand>
            <Navbar.Brand href="/">
            </Navbar.Brand>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="start"
            >
              <Offcanvas.Header closeButton className="close_bttn">

              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className='justify-content-start justify-content-md-center flex-grow-1' navbarScroll>
                  <Nav.Link
                    onClick={() => handleNavItem('home')}
                    active={activeMenu === 'home'}
                    to='/'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleNavItem('features')}
                    active={activeMenu === 'features'}
                    to='/features'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Features
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleNavItem('heatmap')}
                    active={activeMenu === 'heatmap'}
                    to='/heatmap'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Heatmap
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => handleNavItem('pricing')}
                    active={activeMenu === 'pricing'}
                    to='/pricing'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Pricing
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => navigate('/contact-us')}
                    active={activeMenu === '/contact-us'}
                    to='/contact-us'
                    className='mx-8 mb-5 mb-lg-auto px-0 fw-medium text-dark text-active-primary fs-4'
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
                <div className='mt-10 mt-lg-auto'>
                  <button
                    className='btn btn-light-primary px-10 me-4'
                    type='button'
                    onClick={() => navigate('/login')}
                    aria-label='Sign In'
                  >
                    Sign In
                  </button>
                  <button
                    className='btn btn-primary px-10'
                    type='button'
                    onClick={() => navigate('/signup')}
                    aria-label='Sign Up'
                  >
                    Free Trial
                  </button>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </span>
    </div>
  )
}

export default PublichHeader
