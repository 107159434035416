import { apiRefreshInterval } from "./utils/user"

export const typeMap = {
  ELECTRONIC: 'ELEC',
  'Multi_Leg_auto-electronic_trade_against_single_leg(s)': 'MLASL',
  Single_Leg_Auction_Non_ISO: 'SLA',
  ISO_Order: 'ISO',
  'Multi_Leg_auto-electronic_trade': 'MLAE',
  Multi_Leg_Auction: 'MLA',
}
export const SUBSCRIPTION_PLANS = {
  PREMIUM: 'premium',
  PREMIUM_PLUS: 'premium_plus',
  PROFESSIONAL: 'professional',
}

export const stdGridDateFormat = 'MM/DD\xa0\xa0\xa0h:mm:ss A'
export const dateFormat = 'MM-DD-YYYY'
export const refetchInterval =  apiRefreshInterval()
export const availableTradeFields = [
  {
    width: 126,
    tooltip: 'Time of the trade',
    filterType: '',
    value: 'Time',
    key: 'time',
  },
  {
    width: 82,
    tooltip: 'Underlying equity symbol',
    filterType: 'Text',
    value: 'Ticker',
    key: 'ticker',
  },
  {
    width: 85,
    tooltip: 'Stock Price',
    filterType: '',
    value: 'Stock',
    key: 'stockPrice',
  },
  {
    width: 110,
    tooltip: 'Date of contract expiration',
    filterType: 'dateRange',
    value: 'Expiration',
    key: 'contractExpiration',
  },
  {
    width: 85,
    tooltip: '',
    filterType: 'range',
    value: 'Strike',
    key: 'strikePrice',
  },

  {
    width: 106,
    tooltip: 'Contract',
    filterType: 'dropdown',
    value: 'Contract',
    key: 'contractType',
  },
  {
    width: 82,
    tooltip: 'Trade Side',
    filterType: 'dropdown',
    value: 'Side',
    key: 'side',
  },

  {
    width: 90,
    tooltip: 'Bid-Ask Spread',
    filterType: '',
    value: 'Bid-Ask',
    key: 'bid-ask',
  },
  {
    width: 80,
    tooltip: 'Price paid for each contract',
    filterType: 'range',
    value: 'Price',
    key: 'price',
  },
  {
    width: 75,
    tooltip: 'Number of total contracts',
    filterType: 'range',
    value: 'Size',
    key: 'size',
  },
  {
    width: 105,
    tooltip: 'Price * Size',
    filterType: 'range',
    value: 'Premium',
    key: 'premium',
  },
  {
    width: 70,
    tooltip: 'Number of contracts traded today',
    filterType: 'range',
    value: 'Vol.',
    key: 'volume',
  },

  {
    width: 70,
    tooltip: 'Open Interest',
    filterType: '',
    value: 'OI',
    key: 'openInterest',
  },

  {
    width: 80,
    tooltip: 'Trade Execution',
    filterType: 'dropdown',
    value: 'Type',
    key: 'type',
    tooltipField: 'type',
  },
  {
    width: 80,
    tooltip: 'Exchange',
    filterType: 'dropdown',
    value: 'Exch',
    key: 'exchange',
  },

  {
    width: 84,
    tooltip: 'Theta',
    filterType: '',
    value: 'Theta',
    key: 'theta',
  },

  {
    width: 70,
    tooltip: 'IV',
    filterType: '',
    value: 'IV',
    key: 'iv',
  },
  {
    width: 84,
    tooltip: 'Rho',
    filterType: '',
    value: 'Rho',
    key: 'ro',
  },
  {
    width: 84,
    tooltip: 'Gamma',
    filterType: '',
    value: 'Gamma',
    key: 'gamma',
  },
  {
    width: 84,
    tooltip: 'Vega',
    filterType: '',
    value: 'Vega',
    key: 'vega',
  },
  {
    width: 80,
    tooltip: 'Delta',
    filterType: '',
    value: 'Delta',
    key: 'delta',
  },
  {
    width: 80,
    tooltip: 'OTM %',
    filterType: '',
    value: 'OTM %',
    key: 'percentOTM',
  },
  {
    width: 90,
    tooltip: 'Flag',
    filterType: 'dropdown',
    value: 'Flag',
    key: 'flag',
    tooltipField: 'flag',
  },
  {
    width: 120,
    tooltip: 'Sentiment',
    filterType: 'dropdown',
    value: 'Sentiment',
    key: 'sentiment',
  },
  {
    width: 130,
    tooltip: 'Sector',
    //filterType: 'dropdown', //Enable when filtering is available
    value: 'Sector',
    key: 'sector',
  },
  {
    width: 160,
    tooltip: 'Industry',
    //filterType: 'dropdown', //Enable when filtering is available
    value: 'Industry',
    key: 'industry',
  },
]
export const greeksAndIVkeys = ['theta', 'iv', 'delta', 'vega', 'ro', 'gamma']
export const defaultFilterSwitches = [
  // { label: "Volume > OI", key: "volumnGreaterOI", disabled: true },
  // { label: "Size > OI", key: "sizeGreaterOI", disabled: true },
  // { label: "Intraday only", key: "intradayOnly", disabled: false },
  // { label: "Exclude deep itm", key: "excludeDeepItm", disabled: false },
  { label: 'Ask side', key: 'askSide', disabled: false, checked: true },
  { label: 'Bid side', key: 'bidSide', disabled: false, checked: true },
  { label: 'Index only', key: 'index', disabled: false, checked: true },
  { label: 'ETF only', key: 'etf', disabled: false, checked: true },
  { label: 'Expiring Today', key: 'expiringToday', disabled: false, checked: true },
  { label: 'Expiring This Week', key: 'expiringThisWeek', disabled: false, checked: true },
  // { label: "Otm only", key: "otmOnly", disabled: false },
  // { label: "Multileg Only", key: "multilegOnly", disabled: false },
  // { label: "Bearish", key: "bearish", disabled: false },
  // { label: "Bullish", key: "bullish", disabled: false },
]
export const unusualIndicatorsData = [
  {
    key: 'bullish',
    label: 'Bullish',
    border: 'rgba(6, 137, 61, 0.4)',
    backgroundColor: 'rgba(6, 137, 61, 0.15)',
  },
  {
    key: 'bearish',
    label: 'Bearish',
    border: '#DF4759',
    backgroundColor: 'rgba(223, 71, 89, 0.15)',
  },
  {
    key: 'otm',
    label: 'OTM',
    border: '#9747FF',
    backgroundColor: 'rgba(151, 71, 255, 0.15)',
  },
  {
    key: 'itm',
    label: 'ITM',
    border: '#BEC7F5',
    backgroundColor: 'rgba(190, 199, 245, 0.15)',
  },
  {
    key: 'multileg',
    label: 'Multi-Leg',
    border: '#5E4E6A',
    backgroundColor: 'rgba(94, 78, 106, 0.15)',
  },
  {
    key: 'sweep',
    label: 'Sweep',
    border: '#1271E6',
    backgroundColor: 'rgba(18, 113, 230, 0.15)',
  },
  {
    key: 'cross',
    label: 'Cross',
    border: '#c39d4b',
    backgroundColor: 'rgba(228, 198, 136, 0.26)',
  },
  {
    key: 'floor',
    label: 'Floor',
    border: '#E4A21E',
    backgroundColor: 'rgba(228, 162, 30, 0.15)',
  },
]

export const opraRadioQuestions = [
  {
    label: 'Are you a professional or nonprofessional user?',
    options: [
      { label: 'Non Professional', value: false },
      { label: 'Professional', value: true },
    ],
    name: 'professionalUser',
  },
  {
    label: 'Are you an individual or a part of a firm?',
    options: [
      { label: 'Individual', value: false },
      { label: 'Firm', value: true },
    ],
    name: 'firmAffiliation',
  },
  {
    label: 'Will you be redistributing this data?',
    options: [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ],
    name: 'redistributeData',
  },
  {
    label: 'Is this data only for internal use?',
    options: [
      { label: 'Yes', value: false },
      { label: 'No', value: true },
    ],
    name: 'internalUseOnly',
  },
  {
    label: 'Are you registered with FINRA or NFA?',
    options: [
      { label: 'No', value: false },
      { label: 'Yes', value: true },
    ],
    name: 'registeredFinraNfa',
  },
  {
    label: 'Do you intend to access OPRA data from home or another location?',
    options: [
      { label: 'Home', value: true },
      { label: 'Another Location', value: false },
    ],
    name: 'accessLocationHome',
  },
  {
    label: 'Are you the only person using this account?',
    options: [
      { label: 'Yes', value: false },
      { label: 'No', value: true },
    ],
    name: 'soleAccountUser',
  },
  {
    label: 'Are you trading on behalf of other people, or purely for yourself?',
    options: [
      { label: 'Myself', value: false },
      { label: 'Others', value: true },
    ],
    name: 'tradingForOthers',
  },
]

export const PRICING_TABLES = {
  PROFESSIONAL: process.env.REACT_APP_PROFESSIONAL_STRIPE_TABLE_KEY ?? 'prctbl_1P9McGLzfuUk1e0UNAdiPHRn',
  NON_PROFESSIONAL:
    process.env.REACT_APP_NON_PROFESSIONAL_STRIPE_TABLE_KEY ?? 'prctbl_1P9MNoLzfuUk1e0ULEkIlkEB',
}
